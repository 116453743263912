exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-bettle-teams-jsx": () => import("./../../../src/pages/bettle-teams.jsx" /* webpackChunkName: "component---src-pages-bettle-teams-jsx" */),
  "component---src-pages-blog-grid-left-sidebar-jsx": () => import("./../../../src/pages/blog-grid-left-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-grid-left-sidebar-jsx" */),
  "component---src-pages-blog-grid-right-sidebar-jsx": () => import("./../../../src/pages/blog-grid-right-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-grid-right-sidebar-jsx" */),
  "component---src-pages-blog-grid-without-sidebar-jsx": () => import("./../../../src/pages/blog-grid-without-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-grid-without-sidebar-jsx" */),
  "component---src-pages-blog-left-sidebar-jsx": () => import("./../../../src/pages/blog-left-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-left-sidebar-jsx" */),
  "component---src-pages-blog-right-sidebar-jsx": () => import("./../../../src/pages/blog-right-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-right-sidebar-jsx" */),
  "component---src-pages-blog-without-sidebar-jsx": () => import("./../../../src/pages/blog-without-sidebar.jsx" /* webpackChunkName: "component---src-pages-blog-without-sidebar-jsx" */),
  "component---src-pages-character-jsx": () => import("./../../../src/pages/character.jsx" /* webpackChunkName: "component---src-pages-character-jsx" */),
  "component---src-pages-characters-jsx": () => import("./../../../src/pages/characters.jsx" /* webpackChunkName: "component---src-pages-characters-jsx" */),
  "component---src-pages-contact-us-jsx": () => import("./../../../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-match-jsx": () => import("./../../../src/pages/match.jsx" /* webpackChunkName: "component---src-pages-match-jsx" */),
  "component---src-pages-players-jsx": () => import("./../../../src/pages/players.jsx" /* webpackChunkName: "component---src-pages-players-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacyPolicy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-reset-password-jsx": () => import("./../../../src/pages/reset-password.jsx" /* webpackChunkName: "component---src-pages-reset-password-jsx" */),
  "component---src-pages-tacticals-jsx": () => import("./../../../src/pages/tacticals.jsx" /* webpackChunkName: "component---src-pages-tacticals-jsx" */),
  "component---src-pages-weapon-jsx": () => import("./../../../src/pages/weapon.jsx" /* webpackChunkName: "component---src-pages-weapon-jsx" */),
  "component---src-pages-weapons-jsx": () => import("./../../../src/pages/weapons.jsx" /* webpackChunkName: "component---src-pages-weapons-jsx" */),
  "component---src-templates-categories-post-index-jsx": () => import("./../../../src/templates/categories-post/index.jsx" /* webpackChunkName: "component---src-templates-categories-post-index-jsx" */),
  "component---src-templates-date-post-index-jsx": () => import("./../../../src/templates/date-post/index.jsx" /* webpackChunkName: "component---src-templates-date-post-index-jsx" */),
  "component---src-templates-games-details-index-jsx": () => import("./../../../src/templates/games-details/index.jsx" /* webpackChunkName: "component---src-templates-games-details-index-jsx" */),
  "component---src-templates-match-details-index-jsx": () => import("./../../../src/templates/match-details/index.jsx" /* webpackChunkName: "component---src-templates-match-details-index-jsx" */),
  "component---src-templates-players-details-index-jsx": () => import("./../../../src/templates/players-details/index.jsx" /* webpackChunkName: "component---src-templates-players-details-index-jsx" */),
  "component---src-templates-single-blog-post-index-jsx": () => import("./../../../src/templates/single-blog-post/index.jsx" /* webpackChunkName: "component---src-templates-single-blog-post-index-jsx" */),
  "component---src-templates-tag-post-index-jsx": () => import("./../../../src/templates/tag-post/index.jsx" /* webpackChunkName: "component---src-templates-tag-post-index-jsx" */),
  "component---src-templates-teams-details-index-js": () => import("./../../../src/templates/teams-details/index.js" /* webpackChunkName: "component---src-templates-teams-details-index-js" */)
}

